.dark-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
    z-index: 1; /* Ensure the overlay is above the content */
}

.container{
    width: 100% !important;
}

.App-language-toggle {
    border: none;
    
    
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
 
  }

  .list-style-inner{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    --it-btn-color-primary: #2774c2;
    --it-btn-color-disabled: #b2b9c7;
    --button-size: 24px;
    --small-big-ratio: 3;
    --tt-br: 4px;
    --tt-fs: 12px;
    --tt-width: 56px;
    --tt-height: 42px;
    --tt-hk-br: calc(var(--tt-br) / 2);
    --tt-hk-pading: 2px;
    --tt-dx: calc(-1 * ((var(--tt-width) - var(--button-size)) / 2));
    --tt-dy: calc(-1 * (var(--tt-height) / 2 + var(--button-size)));
    --tt-small-dx: calc(-1 * ((var(--tt-width) - var(--button-size) / var(--small-big-ratio)) / 2));
    --tt-small-dy: calc(-1 * (var(--tt-height) / 2 + var(--button-size) / var(--small-big-ratio)));
    --border-radius: 3px;
    --border-style: solid;
    --border: var(--border-width) 
        var(--border-style) 
        var(--border-color);
    --selection-padding: 1px;
    --fill-color: #dde9f8;
    --fill-color-transparent: #0073ff1e;
    --border-color: #519fff;
    --rem: 16;
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --color-primary: #f9004d;
    --color-primary-light: #ffedf2;
    --color-body: #717173;
    --color-heading: #1f1f25;
    --color-secondary: #00d09c;
    --color-tertiary: #030303;
    --color-success: #3eb75e;
    --color-danger: #ff0003;
    --color-border: #e9e9e9;
    --color-border-2: #0000004d;
    --color-lighter: #f8f9fc;
    --color-white: #fff;
    --radius: 4px;
    --border-width: 2px;
    --border-sdfs: #717173;
    --color-extra1: #b1b4c1;
    --h1: 72px;
    --h2: 60px;
    --h3: 40px;
    --h4: 30px;
    --h5: 20px;
    --h6: 16px;
    --page-title-display: block;
    --woocommerce: #7f54b3;
    --wc-green: #7ad03a;
    --wc-red: #a00;
    --wc-orange: #ffba00;
    --wc-blue: #2ea2cc;
    --wc-primary: #7f54b3;
    --wc-primary-text: #fff;
    --wc-secondary: #e9e6ed;
    --wc-secondary-text: #515151;
    --wc-highlight: #b3af54;
    --wc-highligh-text: #fff;
    --wc-content-bg: #fff;
    --wc-subtext: #767676;
    --it-editor-width: clamp(28rem,30vw,36rem);
    --it-editor-height: clamp(14rem,35vh,24rem);
    --it-editor-padding-top: calc(0.5rem + var(--it-top-toolbar-height));
    --it-editor-padding-bottom: calc(0.5rem + var(--it-bottom-toolbar-height)*0.5);
    --it-editor-padding-left: clamp(24px,3%,100px);
    --it-editor-padding-right: var(--it-editor-padding-left);
    --it-editor-msg-bottom-padding: 10px;
    --it-editor-color-bg: var(--it-color-white);
    --it-editor-color-bg-semi-transparent: hsla(0,0%,100%,.9);
    --it-editor-color-scrollbar-bg: #ddd;
    --it-editor-added-bg: rgba(92,185,69,.1);
    --it-editor-added: #5cb945;
    --it-editor-removed: #cc3434;
    --it-color-primary: #3d81d1;
    --it-color-primary-darker: #316bae;
    --it-color-white: #fff;
    --it-color-gray-200: #eff2f7;
    --it-color-gray-800: #3c4858;
    --it-color-green: #5cb945;
    --it-color-red: #e2757a;
    --it-color-text: #2e3238;
    --it-color-contrast-low: var(--it-color-gray-200);
    --it-color-contrast-medium: var(--it-color-gray-800);
    --it-color-bg-change-highlight: #3d81d126;
    --it-radius: .3em;
    --it-radius-md: var(--it-radius,0.25em);
    --it-font-primary: "Inter",sans-serif;
    --it-font-size-base: 15px;
    --it-top-toolbar-height: 44px;
    --it-bottom-toolbar-height: 50px;
    --it-space-unit: 17px;
    --it-space-xxxxs: calc(var(--it-space-unit)*0.125);
    --it-space-xxxs: calc(var(--it-space-unit)*0.25);
    --it-space-xxs: calc(var(--it-space-unit)*0.375);
    --it-space-xs: calc(var(--it-space-unit)*0.5);
    --it-space-sm: calc(var(--it-space-unit)*0.75);
    --it-space-md: calc(var(--it-space-unit)*1.25);
    --it-space-lg: calc(var(--it-space-unit)*2);
    --it-space-xl: calc(var(--it-space-unit)*3.25);
    --it-space-xxl: calc(var(--it-space-unit)*5.25);
    --it-space-xxxl: calc(var(--it-space-unit)*8.5);
    --it-space-xxxxl: calc(var(--it-space-unit)*13.75);
    --it-scrollbar-width: 6px;
    --it-shadow-xs: 0 0.1px 0.3px rgba(0,0,0,.06),0 1px 2px rgba(0,0,0,.12);
    --it-shadow-sm: 0 0.3px 0.4px rgba(0,0,0,.025),0 0.9px 1.5px rgba(0,0,0,.05),0 3.5px 6px rgba(0,0,0,.1);
    --it-shadow-md: 0 0.9px 1.5px rgba(0,0,0,.03),0 3.1px 5.5px rgba(0,0,0,.08),0 14px 25px rgba(0,0,0,.12);
    --it-shadow-lg: 0 1.2px 1.9px -1px rgba(0,0,0,.014),0 3.3px 5.3px -1px rgba(0,0,0,.038),0 8.5px 12.7px -1px rgba(0,0,0,.085),0 30px 42px -1px rgba(0,0,0,.15);
    --it-shadow-xl: 0 1.5px 2.1px -6px rgba(0,0,0,.012),0 3.6px 5.2px -6px rgba(0,0,0,.035),0 7.3px 10.6px -6px rgba(0,0,0,.07),0 16.2px 21.9px -6px rgba(0,0,0,.117),0 46px 60px -6px rgba(0,0,0,.2);
    --wp--preset--color--black: #000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #fff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--color--primary: #f9004d;
    --wp--preset--color--secondary: #00d09c;
    --wp--preset--color--dark: #1f1f25;
    --wp--preset--color--gray: #717173;
    --wp--preset--color--light: #f8f9fc;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg,rgba(6,147,227,1) 0%,#9b51e0 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg,#7adcb4 0%,#00d082 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg,rgba(252,185,0,1) 0%,rgba(255,105,0,1) 100%);
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg,rgba(255,105,0,1) 0%,#cf2e2e 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg,#eee 0%,#a9b8c3 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg,#4aeadc 0%,#9778d1 20%,#cf2aba 40%,#ee2c82 60%,#fb6962 80%,#fef84c 100%);
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg,#ffceec 0%,#9896f0 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg,#fecda5 0%,#fe2d2d 50%,#6b003e 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg,#ffcb70 0%,#c751c0 50%,#4158d0 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg,#fff5cb 0%,#b6e3d4 50%,#33a7b5 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg,#caf880 0%,#71ce7e 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg,#020381 0%,#2874fc 100%);
    --wp--preset--font-size--small: 12px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--font-size--normal: 16px;
    --wp--preset--font-size--huge: 50px;
    --wp--preset--spacing--20: .44rem;
    --wp--preset--spacing--30: .67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
    --wp--preset--shadow--natural: 6px 6px 9px rgba(0,0,0,.2);
    --wp--preset--shadow--deep: 12px 12px 50px rgba(0,0,0,.4);
    --wp--preset--shadow--sharp: 6px 6px 0px rgba(0,0,0,.2);
    --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255,255,255,1),6px 6px rgba(0,0,0,1);
    --wp--preset--shadow--crisp: 6px 6px 0px rgba(0,0,0,1);
    text-align: left;
    font-size: 16px;
    line-height: 30px;
    -webkit-font-smoothing: antialiased;
    font-family: "Poppins",sans-serif;
    color: var(--color-body);
    font-weight: 400;
    --e-global-color-primary: #6ec1e4;
    --e-global-color-secondary: #54595f;
    --e-global-color-text: #7a7a7a;
    --e-global-color-accent: #61ce70;
    --e-global-color-7c68dc95: #4054b2;
    --e-global-color-1d41acfc: #23a455;
    --e-global-color-76845706: #000;
    --e-global-color-6b3e468d: #fff;
    --e-global-color-4d9a0bd5: #f8f9fc;
    --e-global-typography-primary-font-family: "Roboto";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "Roboto Slab";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "Roboto";
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-accent-font-family: "Roboto";
    --e-global-typography-accent-font-weight: 500;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
    --widgets-spacing: 20px 20px;
    box-sizing: border-box;
  }

.feather-icon svg {
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    
        font-family: 'feather' !important;
      color: #00d082;
        font-style: normal;
        font-weight: 100;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
}
.inline-markdown p {
    display: inline;
    margin: 0; /* Optional: Removes the default margin of p tags if needed */
}

.inline-content {
    display:flex;
    vertical-align: middle;  }

    .single-column {
        border: 1px solid #00000010; /* 1px solid black border */
        padding: 20px; /* Optional: Adds some spacing inside the box */
        margin-bottom: 30px; /* Optional: Adds some space between the boxes */
        
        overflow: auto; 
        border-radius: 5%;
      }
     